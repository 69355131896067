require('./bootstrap');

document.addEventListener('DOMContentLoaded', (event) => {
    console.log(document.getElementById('form-search'));

    const submitButton = document.querySelector('#submit-search');

    // Get all the form-check elements
    const formCheckElements = document.querySelectorAll('.form-check');

    // Add event listener to each form-check element
    formCheckElements.forEach(formCheck => {
    formCheck.addEventListener('change', () => {
        // Trigger a click event on the submit button
        submitButton.click();
    });
    });
})